<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader title="Manage Commission" />
        <div class="loader">
          <Wave
            v-if="loading || updating || deleting"
            width="50px"
            color="#d9534f"
          />
        </div>
        <!-- <div>
          <md-button class="md-primary mx-4" :to="{ name: 'Advert Sections' }">
            View Sections
          </md-button>
          <md-button class="md-primary" :to="{ name: 'Create Advert' }">
            Add Advert
          </md-button>
        </div> -->
      </div>
      <!-- {{ commission }} -->
      <md-table
        v-model="commission.mdData"
        md-card
        md-sort="title"
        md-sort-order="asc"
      >
        <md-table-toolbar class="mb-4">
          <div class="md-toolbar-section-start">
            <!-- <md-button @click="exportExcel" class="md-primary button-icon">
              Download Excel
              <img src="@/assets/svg/excel.svg" />
            </md-button> -->
          </div>
          <div class="toolbar-filter-wrapper">
            <md-field md-clearable class="md-toolbar-section-end">
              <md-input placeholder="Search by name..." v-model="search" />
            </md-field>
          </div>
        </md-table-toolbar>
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="S/N">{{ item.id }}</md-table-cell>
          <md-table-cell md-label="Title">{{ item.title }}</md-table-cell>
          <md-table-cell md-label="Percent">{{ item.percent }}%</md-table-cell>

          <md-table-cell md-label="Status">
            <div
              class="badge"
              :class="{
                success: item.visibility_status == 'live',
                grey: item.visibility_status == 'draft',
              }"
            >
              {{ item.visibility_status }}
            </div></md-table-cell
          >
          <md-table-cell md-label="Actions" md-numeric>
            <div class="table-actions">
              <!-- <md-button
                v-if="item.status === 'draft'"
                :disabled="updating"
                md-menu-trigger
                class="bgsuccess btn-sm"
                >Publish</md-button
              > -->
              <md-button
                :disabled="updating"
                md-menu-trigger
                class="bggreen btn-sm"
                :to="{ name: 'Edit Commission', params: { id: item.id } }"
                >Edit</md-button
              >
              <!-- <md-button
                :disabled="updating"
                md-menu-trigger
                class="bggreen btn-icon"
                @click="remove(item.id)"
                ><i class="material-icons">close</i>
                </md-button> -->
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <span v-if="loading" class="b-loading">Loading...</span>
      <pagination
        v-model="commission.mdPage"
        :records="commission.mdCount"
        :per-page="commission.mdPerPage"
        @paginate="updatePagination"
      />
    </md-card>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("commission");
export default {
  name: "Commission",
  data() {
    return {
      updating: false,
      deleting: false,
      search: "",
      time: null,
    };
  },
  watch: {
    search(val) {
      this.waitForIt(val);
    },
  },

  methods: {
    ...mapActions(["index", "getCommission", "deleteCommission"]),
    async updatePagination(page) {
      let data = { page };
      if (this.search) data.keyword = this.search;
      await this.getCommission(data);
    },
    async submitSearch() {
      await this.getCommission({ keyword: this.search });
    },
    async exportExcel() {
      await this.$store.dispatch("auth/export", { model: "advert" });
    },

    waitForIt(val) {
      if (this.time) {
        clearTimeout(this.time);
      }
      this.time = setTimeout(() => this.submitSearch(val), 1000);
    },
    async remove(id) {
      try {
        const conf = confirm("Are you sure?");
        if (conf) {
          this.deleting = true;
          await this.deleteCommission(id);
          this.deleting = false;
          this.toast("success", "Commission Deleted");
        }
      } catch (err) {
        this.deleting = false;

        this.toast("error", "Opps, something went wrong");
      }
    },
  },
  created() {
    this.index();
    console.log("hello");
  },
  mounted() {},
  computed: {
    ...mapGetters(["commission", "loading"]),
  },
};
</script>
<!-- jkfj -->

<style lang="scss" scoped>
.md-table-cell {
  width: 0% !important;
  padding: 0rem 0.8rem;
}

.md-table + .md-table {
  margin-top: 16px;

  thead {
    height: 50px !important;
  }
}

.table-actions {
  width: 100%;
  display: flex;
  justify-content: end;
}
</style>
